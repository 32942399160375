@import url("./fonts/mechanical-pencil.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-cursor {
  background-image: url("../public/img/cursor.webp");
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
}

.deco-hero {
  background-image: url("../public/img/bg/deco-hero.webp");
}

.deco-about {
  background-image: url("../public/img/bg/deco-about.webp");
}

.deco-social {
  background-image: url("../public/img/bg/deco-social.webp");
}

@media only screen and (max-width: 600px) {
  .deco-hero {
    background-image: url("../public/img/bg/deco-hero-mobile.webp");
  }

  .deco-about {
    background-image: url("../public/img/bg/deco-about-mobile.webp");
  }

  .deco-social {
    background-image: url("../public/img/bg/deco-social-mobile.webp");
  }
}
